import API_URL from "../config";
import axios from "axios";

import { getCookie } from "./../utils/cookies";

export const getNumberOfAvailableTickets = async (eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/tickets/read/availableTickets/${id}/${iv}/${eventID}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

// export const getAllTicketsApi = async (eventID) => {
//   try {
//     const token = getCookie("T6hVzR7c0g3maM3F");
//     const iv = getCookie("02hfCmUqpk66w4uG");
//     const id = getCookie("3F2Mb2pJ1YRro5Z4");

//     const option = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     };

//     const res = await axios.get(
//       `${API_URL}/api/V2/tickets/dashboardTickets/${id}/${iv}/${eventID}`,
//       option
//     );
//     return res.data;
//   } catch (err) {
//     throw err;
//   }
// };

export const getAllTicketsApi = async (filterObject, page = 1, limit = 10) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryParams = new URLSearchParams();
    if (filterObject?.eventID) {
      queryParams.append("eventID", filterObject.eventID);
    }
    if (filterObject?.date) {
      queryParams.append("date", filterObject.date);
    }
    if (filterObject?.client) {
      queryParams.append("client", filterObject.client);
    }
    if (filterObject?.ticketID) {
      queryParams.append("ticketID", filterObject.ticketID);
    }
    if (filterObject?.online) {
      queryParams.append("online", filterObject.online);
    }
    if (filterObject?.offline) {
      queryParams.append("offline", filterObject.offline);
    }

    queryParams.append("page", page);
    queryParams.append("limit", limit);

    const res = await axios.get(
      `${API_URL}/api/V2/tickets/dashboardTickets/${id}/${iv}?${queryParams.toString()}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getAllReservationApi = async (filterObject, page = 1, limit = 10) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const queryParams = new URLSearchParams();
    if (filterObject?.commandeId) {
      queryParams.append("commandeId", filterObject.commandeId);
    }
    if (filterObject?.email) {
      queryParams.append("email", filterObject.email);
    }
    if (filterObject?.eventID) {
      queryParams.append("eventID", filterObject.eventID);
    }

    queryParams.append("page", page);
    queryParams.append("limit", limit);

    const res = await axios.get(
      `${API_URL}/api/V2/tickets/dashboardReservations/${id}/${iv}?${queryParams.toString()}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

// export const getAllReservationApi = async (filterObject, page = 1, limit = 10) => {
//   try {
//     const token = getCookie("T6hVzR7c0g3maM3F");
//     const iv = getCookie("02hfCmUqpk66w4uG");
//     const id = getCookie("3F2Mb2pJ1YRro5Z4");

//     const option = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     };

//     const queryParams = new URLSearchParams();
//     if (filterObject?.commandeId) {
//       queryParams.append("commandeId", filterObject.commandeId);
//     }
//     if (filterObject?.email) {
//       queryParams.append("email", filterObject.email);
//     }
//     if (filterObject?.eventId) {
//       queryParams.append("eventId", filterObject.eventId);
//     }

//     queryParams.append("page", page);
//     queryParams.append("limit", limit);

//     const res = await axios.get(
//       `${API_URL}/api/V2/tickets/dashboardReservations/${id}/${iv}?${queryParams.toString()}`,
//       option
//     );
//     return res.data;
//   } catch (err) {
//     throw err;
//   }
// };

export const getOneReservationDetails = async (commandeID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/tickets/dashboardOneReservation/${id}/${iv}/${commandeID}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const resetBuyInfoApi = async (ticketID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${API_URL}/api/V2/tickets/resetTicketBuyInfo/${id}/${iv}/${ticketID}`,
      {},
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const resetScanInfoApi = async (ticketID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${API_URL}/api/V2/tickets/resetTicketScanInfo/${id}/${iv}/${ticketID}`,
      {},
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteTicketApi = async (ticketID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.delete(
      `${API_URL}/api/V2/tickets/resetAllTicketInfo/${id}/${iv}/${ticketID}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const downloadTicketApi = (ticketID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    return `${API_URL}/api/V2/pdf/adminTicket/download/${id}/${iv}/${token}/${ticketID}`;
  } catch (err) {
    throw err;
  }
};

export const sendCommandeTicketsToMailApi = async (commandeBodyData) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const bodyData = {
      commandeDetails: commandeBodyData,
    };

    const res = await axios.post(
      `${API_URL}/api/V2/tickets/sendReservationMail/${id}/${iv}`,
      bodyData,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const addNewReservationApi = async (bodyData) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${API_URL}/api/V2/tickets/addNewReservation/${id}/${iv}`,
      bodyData,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const cancelReservationApi = async (reservationID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${API_URL}/api/V2/tickets/cancelReservation/${id}/${iv}/${reservationID}`,
      {},
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};
