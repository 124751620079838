/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import Swal from "sweetalert2";

import { SpeedDial, SpeedDialAction, Grid, CircularProgress } from "@mui/material";

import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreIcon from "@mui/icons-material/More";
import EditIcon from "@mui/icons-material/Edit";

// Data
import { getAllPlacesApi } from "api/places";
import UserModal from "./components/modals/user-modal";

import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import MDTypography from "components/MDTypography";
import { getAllUsersApi, updateUserApi, deleteUserApi } from "api/users";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Pagination from "@mui/material/Pagination";

function UsersList() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);
  const [tableData, setTableData] = useState({
    users: [],
    total: 0,
    pages: 1,
    page: 1,
  });

  const [openModal, setOpenModal] = useState(false);
  const [openModalItemData, setOpenModalItemData] = useState("");

  const [nomPrenomSearch, setNomPrenomSearch] = useState("");
  const [phoneSearch, setPhoneSearch] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("Tous");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    const storedCurrentPage = localStorage.getItem("usersListPage");
    if (storedCurrentPage) {
      setCurrentPage(parseInt(storedCurrentPage, 10));
      localStorage.removeItem("usersListPage");
    }

    if (initialLoad) {
      getTableData("", "", "", "Tous", currentPage, limitPerPage);
      setInitialLoad(false);
    } else if (isFilterApplied) {
      getTableData(
        nomPrenomSearch,
        phoneSearch,
        emailSearch,
        statusFilter,
        currentPage,
        limitPerPage
      );
      setIsFilterApplied(false);
    } else if (
      !initialLoad &&
      !isFilterApplied &&
      (currentPage !== tableData.page || limitPerPage !== 10)
    ) {
      getTableData("", "", "", "Tous", currentPage, limitPerPage);
    }
  }, [isFilterApplied, currentPage, limitPerPage, initialLoad]);

  const handleToggleStatus = async (userId, newStatus) => {
    try {
      await updateUserApi(userId, { status: newStatus });
      Swal.fire({
        title: "Modifié",
        text: "Statut de l'utilisateur modifié avec succès.",
        icon: "success",
      });
      getTableData(
        nomPrenomSearch,
        phoneSearch,
        emailSearch,
        statusFilter,
        tableData.page,
        limitPerPage
      );
    } catch (error) {
      console.error("Error updating user status:", error);
      Swal.fire({
        title: "Erreur",
        text: "Impossible de modifier le statut de l'utilisateur.",
        icon: "error",
      });
    }
  };

  const handleCloseModal = (params) => {
    setOpenModal(false);
  };

  const handleEditeModal = (Data) => {
    setOpenModalItemData(Data);
    setOpenModal(true);
  };

  const getTableData = async (
    nomPrenom = "",
    phone = "",
    email = "",
    status = "Tous",
    page = 1,
    limit = 10
  ) => {
    console.log("getTableData called with:", {
      nomPrenom,
      phone,
      email,
      status,
      currentPage: page,
      limit,
    });

    setLoading(true);
    try {
      const response = await getAllUsersApi(nomPrenom, phone, email, status, page, limit);
      console.log("User Data:", response);
      setTableData({
        users: response.users,
        total: response.totalUsers,
        pages: response.totalPages,
        page: page,
      });
      setCurrentPage(page);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteItem = async (ID) => {
    try {
      Swal.fire({
        title: "",
        text: "Voulez-vous vraiment supprimer",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteUserApi(ID);
            Swal.fire("Supprimée", "Le User a été supprimé avec succès.", "success");
            getTableData(
              nomPrenomSearch,
              phoneSearch,
              emailSearch,
              statusFilter,
              currentPage,
              limitPerPage
            );
          } catch (err) {
            console.log("Erreur lors de la suppression du User:", err);
            Swal.fire("Erreur", "Une erreur est survenue lors de la suppression du User.", "error");
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRefreshData = () => {
    getTableData(
      nomPrenomSearch,
      phoneSearch,
      emailSearch,
      statusFilter,
      tableData.page,
      limitPerPage
    );
  };

  const handleApplyFilter = () => {
    setLoading(true);
    setCurrentPage(1);
    setIsFilterApplied(true);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setStatusFilter("Activée");
          closeMenu();
        }}
      >
        Activée
      </MenuItem>
      <MenuItem
        onClick={() => {
          setStatusFilter("Disactivée");
          closeMenu();
        }}
      >
        Disactivée
      </MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem
        onClick={() => {
          setStatusFilter("Tous");
          closeMenu();
        }}
      >
        <MDTypography variant="button" color="success" fontWeight="regular">
          Tous
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <UserModal
        openModal={openModal}
        closeModal={handleCloseModal}
        refreshData={handleRefreshData}
        isEdite={true}
        openModalItemData={openModalItemData}
      />
      <DashboardNavbar />
      <MDBox mt={0} mb={9} p={1}>
        <Grid container spacing={1} alignItems="center" mb={2}>
          <Grid item xs={12} md={2.5}>
            <MDInput
              type="text"
              label="Nom et prénom"
              size="small"
              fullWidth
              onChange={(e) => setNomPrenomSearch(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={2.5}>
            <MDInput
              type="text"
              label="N° de téléphone"
              size="small"
              fullWidth
              onChange={(e) => setPhoneSearch(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={2.5}>
            <MDInput
              type="email"
              label="Email"
              size="small"
              fullWidth
              onChange={(e) => setEmailSearch(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={2.5}>
            <MDButton
              variant={menu ? "contained" : "outlined"}
              color="dark"
              onClick={openMenu}
              fullWidth
            >
              Statut ({statusFilter}) <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
          </Grid>
          <Grid item xs={12} md={2}>
            <MDButton variant="contained" color="info" onClick={handleApplyFilter} fullWidth>
              Filter
            </MDButton>
          </Grid>
        </Grid>
        <Card>
          <TableContainer component={Paper} mt={0}>
            <MDBox
              width="15rem"
              ml="auto"
              display="flex"
              justifyContent="right"
              padding={2}
            ></MDBox>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Table aria-label="simple table">
                  <TableHead style={{ display: "table-header-group", important: "true" }}>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          color: "#7b809a",
                          fontSize: "0.7rem",
                          opacity: "0.7",
                          textTransform: "uppercase",
                        }}
                      >
                        Nom et prénom
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#7b809a",
                          fontSize: "0.7rem",
                          opacity: "0.7",
                          textTransform: "uppercase",
                        }}
                      >
                        N° de téléphone
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#7b809a",
                          fontSize: "0.7rem",
                          opacity: "0.7",
                          textTransform: "uppercase",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#7b809a",
                          fontSize: "0.7rem",
                          opacity: "0.7",
                          textTransform: "uppercase",
                        }}
                      >
                        Statut
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#7b809a",
                          fontSize: "0.7rem",
                          opacity: "0.7",
                          textTransform: "uppercase",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.users.map((el, index) => (
                      <TableRow
                        key={el._id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>
                            {el.firstName} {el.lastName}
                          </p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.phone}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.email}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={el.status}
                                onChange={(event) =>
                                  handleToggleStatus(el._id, event.target.checked)
                                }
                                color={el.status ? "success" : "error"}
                              />
                            }
                            label={el.status ? "Activée" : "Disactivée"}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <Box sx={{ height: 80, transform: "translateZ(0px)" }}>
                            <SpeedDial
                              ariaLabel="SpeedDial basic example"
                              sx={{ position: "absolute", top: 0, right: "60%" }}
                              className="custom-speed-dial"
                              icon={<MoreIcon />}
                              direction="left"
                            >
                              <SpeedDialAction
                                key={`edit-${el._id}`}
                                icon={<EditIcon />}
                                tooltipTitle="Modifier"
                                onClick={() => {
                                  localStorage.setItem("usersListPage", currentPage);
                                  navigate(`/users/modifier/${el._id}`);
                                }}
                              />
                              <SpeedDialAction
                                key={`delete-${el._id}`}
                                icon={<DeleteIcon />}
                                tooltipTitle="Supprimer"
                                onClick={() => handleDeleteItem(el._id)}
                              />
                            </SpeedDial>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                {tableData.pages > 1 && (
                  <MDBox display="flex" justifyContent="center" mt={2} mb={2}>
                    <Pagination
                      count={tableData.pages}
                      page={tableData.page}
                      onChange={handlePageChange}
                      color="info"
                    />
                  </MDBox>
                )}
              </>
            )}
          </TableContainer>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UsersList;
