import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import UpdateUserFormFields from "./components/UpdateUserFormFields";
import { useNavigate, useParams } from "react-router-dom";
import { getOneUserApi, updateUserApi } from "api/users";
import Swal from "sweetalert2";

function UpdateUserPage() {
  const navigate = useNavigate();
  const { userId } = useParams();

  const [formValue, setFormValue] = useState({});
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getOneUserApi(userId);
        setUserData(response);
        setFormValue(response);
      } catch (error) {
        console.error("Error fetching user data:", error);
        Swal.fire({
          title: "Erreur",
          text: "Impossible de charger les informations de l'utilisateur.",
          icon: "error",
        });
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const handleGetFormValues = (value) => {
    setFormValue((prevState) => ({
      ...prevState,
      ...value,
    }));
  };

  const handleUpdateUser = async () => {
    try {
      const result = await updateUserApi(userId, formValue);
      console.log("API response:", result);
      Swal.fire({
        title: "Modifié",
        text: result.message || "",
        icon: "success",
      });
      navigate("/users/voir");
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ||
        "Une erreur s'est produite lors de la modification de l'utilisateur.";
      Swal.fire({
        title: "Erreur",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Modifier l'utilisateur
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text">
                  Vous pouvez modifier ici les informations de l'utilisateur.
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="info" onClick={handleUpdateUser}>
                  Enregistrer
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {userData && (
              <UpdateUserFormFields formValues={handleGetFormValues} initialValues={userData} />
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateUserPage;
