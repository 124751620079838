import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import FormField from "layouts/pages/account/components/FormField";
import { Radio, RadioGroup, FormControlLabel, FormControl, Select, MenuItem } from "@mui/material";
import Card from "@mui/material/Card";

function UpdateUserFormFields({ formValues, initialValues }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [adress, setAdress] = useState("");
  const [sex, setSex] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    if (initialValues) {
      setFirstName(initialValues.firstName || "");
      setLastName(initialValues.lastName || "");
      setEmail(initialValues.email || "");
      setPhone(initialValues.phone || "");
      setIsOnline(initialValues.isOnline || false);
      setAdress(initialValues.adress || "");
      setSex(initialValues.sex || "");
      const formattedDate = new Date(initialValues.dateOfBirth).toISOString().slice(0, 10);
      setDateOfBirth(formattedDate || "");
      setCity(initialValues.city || "");
    }
  }, [initialValues]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const actualValue = type === "checkbox" ? checked : value;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "isOnline") {
      setIsOnline(actualValue);
    } else if (name === "adress") {
      setAdress(value);
    } else if (name === "sex") {
      setSex(value);
    } else if (name === "dateOfBirth") {
      setDateOfBirth(value);
    } else if (name === "city") {
      setCity(value);
    }
    formValues({ [name]: actualValue });
  };

  return (
    <>
      <Card>
        {" "}
        <MDBox p={3}>
          <MDTypography variant="h5">Informations de l'utilisateur</MDTypography>
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Prénom *"
                  value={firstName}
                  name="firstName"
                  inputProps={{ type: "text" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Nom de famille *"
                  value={lastName}
                  name="lastName"
                  inputProps={{ type: "text" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Email *"
                  value={email}
                  name="email"
                  inputProps={{ type: "email" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="N° de téléphone *"
                  value={phone}
                  name="phone"
                  inputProps={{ type: "tel" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Adresse"
                  value={adress}
                  name="adress"
                  inputProps={{ type: "text" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Date de naissance"
                  value={dateOfBirth}
                  name="dateOfBirth"
                  inputProps={{ type: "date" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  Sexe
                </MDTypography>
                <FormControl fullWidth>
                  <Select
                    labelId="sex-label"
                    id="sex"
                    name="sex"
                    value={sex}
                    onChange={handleChange}
                  >
                    <MenuItem value="">Non spécifié</MenuItem>
                    <MenuItem value="H">Homme</MenuItem>
                    <MenuItem value="F">Femme</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Ville"
                  value={city}
                  name="city"
                  inputProps={{ type: "text" }}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  Statut
                </MDTypography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="isOnline"
                    name="isOnline"
                    value={isOnline}
                    onChange={handleChange}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Activé" />
                    <FormControlLabel value={false} control={<Radio />} label="Désactivé" />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
            </Grid>
          </MDBox>
        </MDBox>
      </Card>{" "}
    </>
  );
}

export default UpdateUserFormFields;
