/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Swal from "sweetalert2";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewEvent page components
import UpdateCouponFormFields from "./components/UpdateCouponFormFields";
import { updateCouponApi, getOneCouponApi } from "api/coupons";

import { useNavigate, useParams } from "react-router-dom";

function UpdateCouponPage() {
  const navigate = useNavigate();
  const { couponId } = useParams();

  const [formValue, setFormValue] = useState({});
  const [couponData, setCouponData] = useState(null);

  useEffect(() => {
    const fetchCouponData = async () => {
      try {
        const response = await getOneCouponApi(couponId);
        setCouponData(response);
        setFormValue(response);
      } catch (error) {
        console.error("Error fetching coupon data:", error);
        Swal.fire({
          title: "Erreur",
          text: "Impossible de charger les informations du coupon.",
          icon: "error",
        });
      }
    };

    if (couponId) {
      fetchCouponData();
    }
  }, [couponId]);

  const handleUpdateCoupon = async () => {
    try {
      const couponObject = {
        ...formValue,
        eventId: formValue?.eventId?._id || formValue?.eventId,
        siege:
          formValue?.siege?.map((s) => ({
            id: s?.id,
            name: s?.name,
            numberOfUse: s?.numberOfUse,
          })) || "",
      };

      console.log("Sending coupon data for update:", couponObject);

      const result = await updateCouponApi(couponId, couponObject);
      console.log("API response:", result);

      Swal.fire({
        title: "Modifiée",
        text: result.message,
        icon: "success",
      });

      navigate("/coupons/voir");
    } catch (err) {
      const errorMessage =
        err?.response?.data?.error || "Une erreur s'est produite lors de la modification";

      Swal.fire({
        title: errorMessage,
        text: "",
        icon: "error",
      });
    }
  };

  const handleGetFormValues = (value) => {
    try {
      setFormValue((prevState) => ({
        ...prevState,
        ...value,
      }));
      console.log("Updated form value:", value);
    } catch (err) {
      console.log("Error updating form values:", err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Modifier le coupon
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text"></MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="info" onClick={handleUpdateCoupon}>
                  Enregistrer
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {couponData && (
              <UpdateCouponFormFields formValues={handleGetFormValues} initialValues={couponData} />
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateCouponPage;
