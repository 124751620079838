import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import FormField from "layouts/pages/account/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getOneEventSiegeApi, getAllEventApi } from "api/events";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import { Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material";

function UpdateCouponFormFields({ formValues, initialValues }) {
  const [siegeData, setSiegeData] = useState(``);
  const [eventData, setEventData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventName, setEventName] = useState("");
  const [coponCode, setCoponCode] = useState("");
  const [typeOfCopon, setTypeOfCopon] = useState("montant");
  const [reduction, setReduction] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState(initialValues?.status || false);

  const [siegeFormDataValues, setSiegeFormDataValues] = useState([]);

  useEffect(() => {
    const fetchAllEvents = async () => {
      try {
        const eventResponse = await getAllEventApi();
        const eventDataArray = eventResponse.map((el) => ({
          title: el.eventTitle,
          id: el._id,
        }));
        setEventData(eventDataArray);
      } catch (err) {
        console.error("Error fetching events:", err);
      }
    };

    fetchAllEvents();
  }, []);

  useEffect(() => {
    if (initialValues) {
      setCoponCode(initialValues.coponCode || "");
      setTypeOfCopon(initialValues.typeOfCopon || "montant");
      setReduction(initialValues.reduction || "");
      setFromDate(
        initialValues.fromDate ? new Date(initialValues.fromDate).toISOString().split("T")[0] : ""
      );
      setToDate(
        initialValues.toDate ? new Date(initialValues.toDate).toISOString().split("T")[0] : ""
      );
      setStatus(initialValues.status !== undefined ? initialValues.status : false);

      if (initialValues.eventId?._id) {
        fetchSiegeData(initialValues.eventId._id);
        setEventName(initialValues.eventId.eventTitle);
        formValues({ eventId: initialValues.eventId._id });
      }

      if (Array.isArray(initialValues.siege)) {
        const initialSiegeDataFormatted = initialValues.siege.map((siege) => ({
          siegeUId: siege.id,
          numberOfUse: siege.numberOfUse,
          name: siege.name,
          selectedSiegeItem: { id: siege.id, name: siege.name },
        }));
        setSiegeFormDataValues(initialSiegeDataFormatted);

        const initialUpdatedSiege = initialSiegeDataFormatted.map((siegeItem) => ({
          id: siegeItem.siegeUId,
          name: siegeItem.name,
          numberOfUse: siegeItem.numberOfUse,
        }));
        formValues({ siege: initialUpdatedSiege });
      } else {
        setSiegeFormDataValues();
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (initialValues?.eventId?._id && eventData.length > 0) {
      const initialEvent = eventData.find((event) => event.id === initialValues.eventId._id);
      setSelectedEvent(initialEvent || null);
    }
  }, [initialValues, eventData]);

  const fetchSiegeData = async (eventId) => {
    try {
      const oneEventDetails = await getOneEventSiegeApi(eventId);
      setSiegeData(oneEventDetails.eventSiege);
      console.log("oneEventDetails.eventSiege:", oneEventDetails.eventSiege);
    } catch (error) {
      console.error("Error fetching siege data:", error);
    }
  };

  const handleSiegeNumberOfUseInputChange = (index, event) => {
    const { value } = event.target;
    const newSiegeFormDataValues = siegeFormDataValues.map((siegeItem, i) => {
      if (index === i) {
        return { ...siegeItem, numberOfUse: parseInt(value, 10) || 0 };
      }
      return siegeItem;
    });
    setSiegeFormDataValues(newSiegeFormDataValues);
    // Update formValues whenever siege data changes
    const updatedSiege = newSiegeFormDataValues.map((siegeItem) => ({
      id: siegeItem.siegeUId,
      name: siegeItem.name,
      numberOfUse: siegeItem.numberOfUse,
    }));
    formValues({ siege: updatedSiege });
  };

  const handleAutoCompleteSiegeChange = (index, event, newValue) => {
    setSiegeFormDataValues((prevSiegeFormDataValues) => {
      const updatedSiegeFormDataValues = [...prevSiegeFormDataValues];
      updatedSiegeFormDataValues[index] = {
        ...updatedSiegeFormDataValues[index],
        siegeUId: newValue?.id || "",
        name: newValue?.name || "",
        selectedSiegeItem: newValue,
      };
      // Update formValues whenever siege data changes
      const updatedSiege = updatedSiegeFormDataValues.map((siegeItem) => ({
        id: siegeItem.siegeUId,
        name: siegeItem.name,
        numberOfUse: siegeItem.numberOfUse,
      }));
      formValues({ siege: updatedSiege });
      return updatedSiegeFormDataValues;
    });
  };

  const handleAddNewSiege = () => {
    setSiegeFormDataValues([
      ...siegeFormDataValues,
      {
        siegeUId: "",
        numberOfUse: 0,
        name: "",
        selectedSiegeItem: null,
      },
    ]);
  };

  const handleDeleteSiege = (index) => {
    const updatedSiegeFormDataValues = siegeFormDataValues.filter((_, i) => i !== index);
    setSiegeFormDataValues(updatedSiegeFormDataValues);

    // Update formValues whenever siege data changes
    const updatedSiege = updatedSiegeFormDataValues.map((siegeItem) => ({
      id: siegeItem.siegeUId,
      name: siegeItem.name,
      numberOfUse: siegeItem.numberOfUse,
    }));
    formValues({ siege: updatedSiege });
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const actualValue = type === "checkbox" ? checked : value;
    formValues({ [name]: actualValue });
    if (name === "coponCode") {
      setCoponCode(value);
    } else if (name === "typeOfCopon") {
      setTypeOfCopon(value);
    } else if (name === "reduction") {
      setReduction(value);
    } else if (name === "fromDate") {
      setFromDate(value);
    } else if (name === "toDate") {
      setToDate(value);
    } else if (name === "status") {
      setStatus(actualValue);
    }
  };

  const handleAutoCompleteEventChange = async (event, newValue) => {
    try {
      const newFormValues = {
        ...formValues,
        eventId: newValue ? newValue?.id : "",
      };
      formValues(newFormValues);
      setSelectedEvent(newValue);
      if (newValue?.id) {
        fetchSiegeData(newValue.id);
      } else {
        setSiegeData();
      }
    } catch (err) {
      console.error("Error handling event change:", err);
    }
  };

  return (
    <>
      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5">Informations du coupon</MDTypography>
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  value={selectedEvent}
                  options={eventData}
                  getOptionLabel={(option) => option?.title || ""}
                  onChange={handleAutoCompleteEventChange}
                  renderInput={(params) => <TextField {...params} label="Evénement" />}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Code coupon *"
                  value={coponCode}
                  name="coponCode"
                  inputProps={{ type: "text" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  Type (montant/pourcentage)
                </MDTypography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="typeOfCopon-label"
                    name="typeOfCopon"
                    value={typeOfCopon}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="montant" control={<Radio />} label="Montant" />
                    <FormControlLabel value="pourcentage" control={<Radio />} label="Pourcentage" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Réduction *"
                  value={reduction}
                  name="reduction"
                  inputProps={{ type: "number", step: "0.01" }}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  Statut
                </MDTypography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="status"
                    name="status"
                    value={status}
                    onChange={handleChange}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Actif" />
                    <FormControlLabel value={false} control={<Radio />} label="Inactif" />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Date de début *"
                  value={fromDate}
                  name="fromDate"
                  inputProps={{ type: "date" }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  label="Date de fin *"
                  value={toDate}
                  name="toDate"
                  inputProps={{ type: "date" }}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>

      <Card style={{ marginTop: "1rem" }}>
        <MDBox p={3}>
          <MDTypography variant="h5">Sièges</MDTypography>
          {Array.isArray(siegeFormDataValues) &&
            siegeFormDataValues.map((el, index) => (
              <MDBox mt={1} key={index}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      value={el.selectedSiegeItem}
                      options={siegeData}
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={(event, newValue) =>
                        handleAutoCompleteSiegeChange(index, event, newValue)
                      }
                      renderInput={(params) => <TextField {...params} label="Siège" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      type="number"
                      label="Nombre d'utilisations"
                      name="numberOfUse"
                      value={el.numberOfUse}
                      onChange={(e) => handleSiegeNumberOfUseInputChange(index, e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ display: "flex", alignItems: "center" }}>
                    {siegeFormDataValues.length - 1 === index &&
                    siegeFormDataValues.length < siegeData?.length ? (
                      <Icon
                        color="success"
                        fontSize="medium"
                        style={{ cursor: "pointer" }}
                        onClick={handleAddNewSiege}
                      >
                        add
                      </Icon>
                    ) : siegeFormDataValues.length > 1 ? (
                      <Icon
                        color="error"
                        fontSize="medium"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteSiege(index)}
                      >
                        delete
                      </Icon>
                    ) : null}
                  </Grid>
                </Grid>
              </MDBox>
            ))}
        </MDBox>
      </Card>
    </>
  );
}

export default UpdateCouponFormFields;
