import axios from "axios";
import API_URL from "../config";
import { getCookie } from "./../utils/cookies";

export const addNewCouponApi = async (formValues) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const objectData = {
      eventId: formValues.eventId,
      coponCode: formValues.coponCode,
      reduction: formValues.reduction,
      fromDate: formValues.fromDate,
      toDate: formValues.toDate,
      typeOfCopon: formValues.typeOfCopon,
      siege: formValues.siege || [],
    };

    const res = await axios.post(
      `${API_URL}/api/V2/coupon/create/${id}/${iv}/${objectData.eventId}`,
      objectData,
      option
    );
    return res.data;
  } catch (err) {
    console.error("Erreur lors de l'ajout du coupon:", err);
    throw err;
  }
};

export const getAllCouponsApi = async (couponCode, reduction, typeOfCopon) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${API_URL}/api/V2/coupon/coupons/${id}/${iv}`;
    const queryParams = [];

    if (couponCode) {
      queryParams.push(`couponCode=${couponCode}`);
    }
    if (reduction) {
      queryParams.push(`reduction=${reduction}`);
    }
    if (typeOfCopon && typeOfCopon !== "Tous") {
      queryParams.push(`typeOfCopon=${typeOfCopon}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    const res = await axios.get(url, option);

    return res.data;
  } catch (err) {
    console.error("Erreur lors de la récupération des coupons:", err);
    throw err;
  }
};

export const updateCouponApi = async (couponId, formValues) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");
    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const objectData = {
      eventId: formValues.eventId,
      coponCode: formValues.coponCode,
      reduction: formValues.reduction,
      fromDate: formValues.fromDate,
      toDate: formValues.toDate,
      typeOfCopon: formValues.typeOfCopon,
      siege: formValues.siege || [],
      status: formValues.status,
    };

    const res = await axios.put(
      `${API_URL}/api/V2/coupon/update/${id}/${iv}/${couponId}`,
      objectData,
      option
    );
    return res.data;
  } catch (err) {
    console.error("Erreur lors de la mise à jour du coupon:", err);
    throw err;
  }
};

export const getOneCouponApi = async (couponId) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");
    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(`${API_URL}/api/V2/coupon/details/${id}/${iv}/${couponId}`, option);
    return res.data;
  } catch (err) {
    console.error("Erreur lors de la récupération des détails du coupon:", err);
    throw err;
  }
};

export const deleteCoponApi = async (couponId) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.delete(
      `${API_URL}/api/V2/coupon/delete/${id}/${iv}/${couponId}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};
