import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";

// formik components
import { Form } from "formik";
import Swal from "sweetalert2";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { Box, Button, CircularProgress, Dialog, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { downloadTicketApi } from "api/tickets";

function ReservationModal({ openModal, closeModal, openModalItemData }) {
  useEffect(() => {
    getInitialValue();
  }, [openModalItemData]);

  const getInitialValue = () => {
    try {
      console.log("openModalItemData", openModalItemData)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDownloadPdf = (id) => {
    try {
      const url = downloadTicketApi(id)
      window.open(url, '_blank')
    }
    catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <Dialog open={openModal} onClose={closeModal} fullWidth maxWidth="sm">
        <MDBox style={{ height: '80vh', overflow: 'auto' }}>
          <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            {
              openModalItemData?.tickets?.map((el, index) => (
                <Grid item xs={12}>
                  <MDBox style={{ padding: '10px' }}>
                    <DefaultInfoCard
                      icon="confirmation_number"
                      title={`${el?.eventID?.eventTitle}`}
                      description={`Ticket (${index+1})`}
                      value={`${el?.price} MAD`}
                    />
                  </MDBox>
                  <MDBox item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <MDButton variant="gradient" color="info" onClick={() => handleDownloadPdf(el._id)}>Télécharger PDF</MDButton>
                  </MDBox>
                </Grid>
              ))
            }
          </Grid>
        </MDBox>
      </Dialog>
    </div>
  );
}

ReservationModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ReservationModal;
