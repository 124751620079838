/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import Swal from "sweetalert2";

import { Grid, SpeedDial, SpeedDialAction, CircularProgress } from "@mui/material";

import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreIcon from "@mui/icons-material/More";
import EditIcon from "@mui/icons-material/Edit";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

// Data
import { getAllCouponsApi, deleteCoponApi, updateCouponApi } from "api/coupons";
import CouponModal from "./components/modals/coupon-modal";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";

function CouponList() {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);
  const [tableRowData, setTableRowData] = useState();

  //
  const [filterType, setFilterType] = useState("Tous");
  const [couponCodeSearch, setCouponCodeSearch] = useState("");
  const [reductionSearch, setReductionSearch] = useState("");
  const [allCoupons, setAllCoupons] = useState(``);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState({});

  useEffect(() => {
    if (isFilterApplied) {
      getTableData();
      setIsFilterApplied(false);
    }
  }, [isFilterApplied]);

  useEffect(() => {
    getTableData();
  }, []);

  const handleApplyFilter = () => {
    setLoading(true);
    setIsFilterApplied(true);
  };

  const getTableData = async () => {
    try {
      const tableData = await getAllCouponsApi(couponCodeSearch, reductionSearch, filterType);

      console.log("Coupon Data:", tableData);
      setAllCoupons(tableData);
      setTableRowData(tableData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteItem = async (ID) => {
    try {
      Swal.fire({
        title: "",
        text: "Voulez-vous vraiment supprimer",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteCoponApi(ID);
            Swal.fire("Supprimée", "Le coupon a été supprimé avec succès.", "success");
            handleRefreshData();
          } catch (err) {
            console.log("Erreur lors de la suppression du coupon:", err);
            Swal.fire(
              "Erreur",
              "Une erreur est survenue lors de la suppression du coupon.",
              "error"
            );
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRefreshData = () => {
    getTableData();
  };
  const handleUpdateCouponStatus = useCallback(
    async (couponId, newStatus) => {
      // Sets the loading state for the specific coupon being updated to true.
      setUpdateLoading((prevLoading) => ({ ...prevLoading, [couponId]: true }));
      try {
        // Finds the coupon data from the 'allCoupons' array based on the provided 'couponId'.
        const couponData = allCoupons.find((coupon) => coupon._id === couponId);
        // Checks if the coupon data or its 'eventId' is missing.
        if (!couponData || !couponData.eventId) {
          // Logs an error message to the console.
          console.error("Event ID not found for coupon:", couponId);
          // Displays an error message to the user using Swal.fire.
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: `Event ID not found for this coupon.`,
          });
          // Sets the loading state for the specific coupon back to false.
          setUpdateLoading((prevLoading) => ({ ...prevLoading, [couponId]: false }));
          // Exits the function early.
          return;
        }

        // Extracts the 'eventId' from the 'couponData'. If 'eventId' is an object and not null,
        // it takes the '_id' property; otherwise, it uses the 'eventId' directly.
        const eventIdToSend =
          typeof couponData.eventId === "object" && couponData.eventId !== null
            ? couponData.eventId._id
            : couponData.eventId;

        // Transforms the 'siege' data if it's an array. It maps over the array and creates a new
        // object for each item with 'id', 'name', and 'numberOfUse' properties, prioritizing
        // 'siege.id' if available, otherwise using 'siege._id'. If 'couponData.siege' is not an array,
        // 'transformedSiege' will be an empty string.
        const transformedSiege = Array.isArray(couponData.siege)
          ? couponData.siege.map((siege) => ({
              id: siege.id || siege._id,
              name: siege.name,
              numberOfUse: siege.numberOfUse,
            }))
          : "";

        // Creates an object containing the data to be sent for updating the coupon.
        const updatedCouponData = {
          eventId: eventIdToSend,
          coponCode: couponData.coponCode,
          reduction: couponData.reduction,
          fromDate: couponData.fromDate,
          toDate: couponData.toDate,
          typeOfCopon: couponData.typeOfCopon,
          siege: transformedSiege,
          status: newStatus, // Uses the new status provided to the function.
        };

        // Calls the 'updateCouponApi' function to send the update request to the backend.
        await updateCouponApi(couponId, updatedCouponData);
        // Updates the local state 'tableRowData' by mapping over it and updating the status
        // of the coupon with the matching 'couponId'.
        setTableRowData((prevData) =>
          prevData.map((coupon) =>
            coupon._id === couponId ? { ...coupon, status: newStatus } : coupon
          )
        );
        // Updates the local state 'allCoupons' similarly to 'tableRowData'.
        setAllCoupons((prevData) =>
          prevData.map((coupon) =>
            coupon._id === couponId ? { ...coupon, status: newStatus } : coupon
          )
        );
        // Displays a success message to the user using Swal.fire.
        Swal.fire({
          icon: "success",
          title: "Updated!",
          text: `Coupon status updated successfully.`,
          timer: 1500,
          showConfirmButton: false,
        });
      } catch (error) {
        // Logs an error message to the console if an error occurs during the process.
        console.error("Error updating coupon status:", error);
        // Displays an error message to the user using Swal.fire.
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: `An error occurred while updating the coupon status.`,
        });
      } finally {
        // Sets the loading state for the specific coupon back to false, regardless of success or failure.
        setUpdateLoading((prevLoading) => ({ ...prevLoading, [couponId]: false }));
      }
    },
    // Defines the dependencies for the 'useCallback' hook. The function will only be re-created
    // if any of these dependencies change.
    [updateCouponApi, setTableRowData, setAllCoupons, allCoupons]
  );
  const handleSwitchChange = (couponId, isChecked) => {
    handleUpdateCouponStatus(couponId, isChecked);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setFilterType("montant");
          closeMenu();
        }}
      >
        Montant
      </MenuItem>
      <MenuItem
        onClick={() => {
          setFilterType("pourcentage");
          closeMenu();
        }}
      >
        Pourcentage
      </MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem
        onClick={() => {
          setFilterType("Tous");
          closeMenu();
        }}
      >
        <MDTypography variant="button" color="success" fontWeight="regular">
          Tous
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3} alignItems="center" mb={4}>
          <Grid item xs={12} lg={4}>
            <MDButton
              variant={menu ? "contained" : "outlined"}
              color="dark"
              onClick={openMenu}
              fullWidth
            >
              Montant / Pourcentage {filterType}
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
          </Grid>
          <Grid item xs={12} lg={3}>
            <MDInput
              label="Code coupon"
              size="large"
              fullWidth
              onChange={(e) => {
                setCouponCodeSearch(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <MDInput
              type="number"
              label="Réduction"
              size="large"
              fullWidth
              onChange={(e) => {
                setReductionSearch(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <MDButton variant="contained" color="info" onClick={handleApplyFilter}>
              Filter
            </MDButton>
          </Grid>
        </Grid>
        <Card>
          <TableContainer component={Paper}>
            <MDBox
              width="15rem"
              ml="auto"
              display="flex"
              justifyContent="right"
              padding={2}
            ></MDBox>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                <CircularProgress />
              </Box>
            ) : (
              <Table aria-label="simple table">
                <TableHead style={{ display: "table-header-group", important: "true" }}>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Code coupon
                    </TableCell>
                    {/* <TableCell
                    align="left"
                    style={{
                      color: "#7b809a",
                      fontSize: "0.7rem",
                      opacity: "0.7",
                      textTransform: "uppercase",
                    }}
                  >
                    Créateur
                  </TableCell> */}
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Réduction
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Siège
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Statut
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Événement
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.7rem",
                        opacity: "0.7",
                        textTransform: "uppercase",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRowData &&
                    tableRowData.map((el, index) => (
                      <TableRow
                        key="index"
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.coponCode || "Non défini"}</p>
                        </TableCell>
                        {/* <TableCell
                      align="left"
                      style={{
                        color: "#7b809a",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                        maxWidth: 100,
                      }}
                    >
                      <p>{el.createdAt}</p>
                    </TableCell> */}
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.typeOfCopon}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.reduction}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>
                            {el.siege && Array.isArray(el.siege)
                              ? el.siege.map((s) => <div key={s.id}>{s.name}</div>)
                              : "Non défini"}
                          </p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <Switch
                            checked={el.status}
                            name={`status-${el._id}`}
                            onChange={(event) => handleSwitchChange(el._id, event.target.checked)}
                            disabled={updateLoading[el._id]}
                          />
                          {updateLoading[el._id] && (
                            <CircularProgress size={20} sx={{ ml: 1, verticalAlign: "middle" }} />
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <p>{el.eventId?.eventTitle || "Non défini"}</p>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#7b809a",
                            fontSize: "0.8rem",
                            fontWeight: "600",
                            maxWidth: 100,
                          }}
                        >
                          <Box sx={{ height: 80, transform: "translateZ(0px)" }}>
                            <SpeedDial
                              ariaLabel="SpeedDial basic example"
                              sx={{ position: "absolute", top: 0, right: "60%" }}
                              className="custom-speed-dial"
                              icon={<MoreIcon />}
                              direction="left"
                            >
                              <SpeedDialAction
                                key={`edit-${el._id}`}
                                icon={<EditIcon />}
                                tooltipTitle="Modifier"
                                onClick={() => navigate(`/coupons/modifier/${el._id}`)}
                              />
                              <SpeedDialAction
                                key={""}
                                icon={<DeleteIcon />}
                                tooltipTitle="Supprimer"
                                onClick={() => handleDeleteItem(el._id)}
                              />
                            </SpeedDial>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CouponList;
