import axios from "axios";
import API_URL from "../config";
import { getCookie } from "./../utils/cookies";

// export const getAllUsersApi = async () => {
//     try {
//         const token = getCookie("T6hVzR7c0g3maM3F")
//         const iv = getCookie("02hfCmUqpk66w4uG")
//         const id = getCookie("3F2Mb2pJ1YRro5Z4")

//         const option = {
//             headers: {
//                 Authorization: `Bearer ${token}`
//             }
//         }

//         const res = await axios.get(`${API_URL}/api/V2/user/all/${id}/${iv}`, option);
//         return res.data
//     }
//     catch (err) {
//         throw err
//     }
// }

export const getAllUsersApi = async (nomPrenom, phone, email, status, page = 1, limit = 10) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${API_URL}/api/V2/user/all/${id}/${iv}`;
    const queryParams = [];

    if (nomPrenom) {
      queryParams.push(`name=${nomPrenom}`);
    }
    if (phone) {
      queryParams.push(`phone=${phone}`);
    }
    if (email) {
      queryParams.push(`email=${email}`);
    }
    if (status && status !== "Tous") {
      const isActivated = status === "Activée";
      queryParams.push(`isOnline=${isActivated}`);
    }

    queryParams.push(`page=${page}`);
    queryParams.push(`limit=${limit}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    const res = await axios.get(url, option);
    return res.data;
  } catch (err) {
    throw err;
  }
};
export const getOneUserApi = async (userId) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");
    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(`${API_URL}/api/V2/user/user/${id}/${iv}/${userId}`, option);
    return res.data;
  } catch (err) {
    console.error("Erreur lors de la récupération des détails du coupon:", err);
    throw err;
  }
};

// export const updateUserApi = async (userId, formValues) => {
//   try {
//     const token = getCookie("T6hVzR7c0g3maM3F");
//     const iv = getCookie("02hfCmUqpk66w4uG");
//     const id = getCookie("3F2Mb2pJ1YRro5Z4");
//     const option = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     };
//     const objectData = { ...formValues };

//     const res = await axios.put(
//       `${API_URL}/api/V2/user/user/${id}/${iv}/${userId}`,
//       objectData,
//       option
//     );
//     return res.data;
//   } catch (err) {
//     console.error("Erreur lors de la mise à jour du coupon:", err);
//     throw err;
//   }
// };

export const updateUserApi = async (userId, formValues) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");
    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.put(
      `${API_URL}/api/V2/user/user/${id}/${iv}/${userId}`,
      formValues,
      option
    );
    return res.data;
  } catch (err) {
    console.error("Erreur lors de la mise à jour de l'utilisateur:", err);
    throw err;
  }
};

export const deleteUserApi = async (userId) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.delete(`${API_URL}/api/V2/user/delete/${id}/${iv}/${userId}`, option);
    return res.data;
  } catch (err) {
    throw err;
  }
};
