import API_URL from "../config";
import axios from "axios";

import { getCookie } from "./../utils/cookies";

export const addNewEventImgsApi = async (formObjectData, eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const formData = new FormData();

    formData.append("eventMainImg", formObjectData?.eventMainImg);
    formData.append("eventCoverImg", formObjectData?.eventCoverImg);
    formData.append("eventSiegesImg", formObjectData?.eventSiegesImg);

    // Append each image in the otherEventImgs array
    if (formObjectData?.otherEventImgs && Array.isArray(formObjectData.otherEventImgs)) {
      formObjectData.otherEventImgs.forEach((image, index) => {
        formData.append(`otherEventImgs`, image);
      });
    }

    const res = await axios.post(
      `${API_URL}/api/V2/eventImg/create/${id}/${iv}/${eventID}`,
      formData,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const addNewEventApi = async (formValues) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const objectData = {
      eventTitle: formValues.eventTitle,
      promoVideo: formValues.promoVideo,
      address: formValues.address,
      description: formValues.description,
      eventPlace: formValues.eventPlace,
      eventCategory: formValues.eventCategory,
      eventSiege: formValues.eventSiege,
      eventStand: formValues.eventStand,
      socialLink: formValues.socialLink,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      eventDate: formValues.eventDate,
      onlineReservation: formValues.onlineReservation,
      isSelectetivePlaces: formValues.isSelectetivePlaces,
      seatIoId: formValues?.seatIoId,
      eventCloseDoorDate: formValues?.eventCloseDoorDate,
      isSoldOut: false,
    };

    const res = await axios.post(`${API_URL}/api/V2/event/create/${id}/${iv}`, objectData, option);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateEventImgsApi = async (formObjectData, eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const formData = new FormData();

    formData.append("eventMainImg", formObjectData?.eventMainImg);
    formData.append("eventCoverImg", formObjectData?.eventCoverImg);
    formData.append("eventSiegesImg", formObjectData?.eventSiegesImg);

    // Append each image in the otherEventImgs array
    if (formObjectData?.otherEventImgs && Array.isArray(formObjectData.otherEventImgs)) {
      formObjectData.otherEventImgs.forEach((image, index) => {
        formData.append(`otherEventImgs`, image);
      });
    }

    const res = await axios.put(
      `${API_URL}/api/V2/eventImg/update/${id}/${iv}/${eventID}`,
      formData,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateEventApi = async (formValues, eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const objectData = {
      eventTitle: formValues.eventTitle,
      promoVideo: formValues.promoVideo,
      address: formValues.address,
      description: formValues.description,
      eventPlace: formValues.eventPlace,
      eventCategory: formValues.eventCategory,
      eventSiege: formValues.eventSiege,
      eventStand: formValues.eventStand,
      socialLink: formValues.socialLink,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      eventDate: formValues.eventDate,
      onlineReservation: formValues.onlineReservation,
      isSelectetivePlaces: formValues.isSelectetivePlaces,
      seatIoId: formValues?.seatIoId,
      eventCloseDoorDate: formValues?.eventCloseDoorDate,
      isSoldOut: formValues?.isSoldOut,
    };

    console.log("objectData", objectData);

    const res = await axios.put(
      `${API_URL}/api/V2/event/update/${id}/${iv}/${eventID}`,
      objectData,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

// export const getAllEventApi = async (publishedOnly, category,) => {
//   try {
//     const token = getCookie("T6hVzR7c0g3maM3F");
//     const iv = getCookie("02hfCmUqpk66w4uG");
//     const id = getCookie("3F2Mb2pJ1YRro5Z4");

//     const option = {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     };

//     let url = `${API_URL}/api/V2/event/read/${id}/${iv}`;

//     if (publishedOnly) {
//       url += `?publishedOnly=true`;
//     }

//     if (category) {
//       url += `${publishedOnly ? "&" : "?"}category=${category}`;
//     }

//     const res = await axios.get(url, option);
//     return res.data;
//   } catch (err) {
//     throw err;
//   }
// };

export const getAllEventApi = async (publishedOnly, category, eventTitle, page, limit) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${API_URL}/api/V2/event/read/${id}/${iv}`;

    let hasQueryParams = false;

    if (publishedOnly) {
      url += `?publishedOnly=true`;
      hasQueryParams = true;
    }

    if (category) {
      url += `${hasQueryParams ? "&" : "?"}category=${category}`;
      hasQueryParams = true;
    }

    if (eventTitle) {
      url += `${hasQueryParams ? "&" : "?"}eventTitle=${eventTitle}`;
      hasQueryParams = true;
    }

    if (page) {
      url += `${hasQueryParams ? "&" : "?"}page=${page}`;
      hasQueryParams = true;
    }

    if (limit) {
      url += `${hasQueryParams ? "&" : "?"}limit=${limit}`;
    }

    const res = await axios.get(url, option);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getOneEventImgsApi = async (eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/eventImg/readOne/${id}/${iv}/${eventID}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getOneEventApi = async (eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(`${API_URL}/api/V2/event/readOne/${id}/${iv}/${eventID}`, option);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getOneEventSiegeApi = async (eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/event/readOne/eventSiege/${id}/${iv}/${eventID}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteEventApi = async (eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.delete(`${API_URL}/api/V2/event/delete/${id}/${iv}/${eventID}`, option);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const publishEventApi = async (eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.put(
      `${API_URL}/api/V2/event/publish/${id}/${iv}/${eventID}`,
      {},
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const eventStatusApi = async (eventID, status) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.put(
      `${API_URL}/api/V2/event/status/${id}/${iv}/${eventID}/${status ? "active" : "inactive"}`,
      {},
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const eventSliderStatusApi = async (eventID, status) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.put(
      `${API_URL}/api/V2/event/sliderStatus/${id}/${iv}/${eventID}/${
        status ? "active" : "inactive"
      }`,
      {},
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const downloadOneEventTicketsExcelApi = async (eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${API_URL}/api/V2/event/getOneEventTicketsExcel/${id}/${iv}/${eventID}`;

    const res = await axios.get(url, option);

    return res.data;
  } catch (err) {
    throw err;
  }
};
export const downloadOneEventReservationExcelApi = async (eventID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const url = `${API_URL}/api/V2/event/getOneEventReservationExcel/${id}/${iv}/${eventID}`;

    const res = await axios.get(url, option);

    return res.data;
  } catch (err) {
    throw err;
  }
};
