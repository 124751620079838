/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import EventCategories from "../../modals/Categories";
import EventPlaces from "../../modals/Places";
import EventSieges from "../../modals/Sieges";
import EventStands from "../../modals/Stands";
import EventSocial from "../../modals/Socials";

import { getAllCategoriesApi } from "api/categories";
import { getAllPlacesApi } from "api/places";
import { getAllStandsApi } from "api/stands";
import { getAllSiegesApi } from "api/sieges";
import { getAllSocialsApi } from "api/socials";

import Icon from "@mui/material/Icon";
import EventSelectedSieges from "../../modals/Sieges/selectedSieges";
import EventSelectedStands from "../../modals/Stands/selectedStands";
import EventSelectedSocial from "../../modals/Socials/selectedSocials";
import EventSelectedPlaces from "../../modals/Places/selectedPlaces";

function FormFields({ formValues }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("sm"));

  const [open, setOpen] = useState(false);
  const [openEventPlaces, setOpenEventPlaces] = useState(false);
  const [openEventSieges, setOpenEventSieges] = useState(false);
  const [openEventStand, setOpenEventStand] = useState(false);
  const [openEventSocial, setOpenEventSocial] = useState(false)

  const [openEventSelectedPlaces, setOpenEventSelectedPlaces] = useState(false);
  const [openEventSelectedSieges, setOpenEventSelectedSieges] = useState(false);
  const [openEventSelectedStand, setOpenEventSelectedStand] = useState(false);
  const [openEventSelectedSocial, setOpenEventSelectedSocial] = useState(false)

  const [categoriesData, setCategoriesData] = useState([])
  const [placesData, setPlacesData] = useState([])
  const [standsData, setStandsData] = useState([])
  const [siegesData, setSiegesData] = useState([])
  const [socialsData, setSocialsData] = useState([])

  // const [selectedCategoriesData, setselectedCategoriesData] = useState([])
  // const [selectedPlacesData, setselectedPlacesData] = useState([])
  // const [selectedStandsData, setselectedStandsData] = useState([])
  // const [selectedSiegesData, setselectedSiegesData] = useState([])

  const [formDataValues, setFormDataValues] = useState({
    eventTitle: formValues?.eventTitle,
    promoVideo: formValues?.promoVideo,
    address: formValues?.address,
    socialLink: formValues?.socialLink,
    eventPlace: formValues?.eventPlace,
    eventCategory: formValues?.eventCategory,
    eventStand: formValues?.eventStand,
    eventSiege: formValues?.eventSiege,
    startDate: formValues?.startDate,
    startTime: formValues?.startTime,
    endDate: formValues?.endDate,
    endTime: formValues?.endTime,
    eventDate: formValues?.eventDate,
    eventTime: formValues?.eventTime,

    eventDoorClosingDate: formValues?.eventDoorClosingDate,
    eventDoorClosingTime: formValues?.eventDoorClosingTime,

    onlineReservation: true,
    isSelectetivePlaces: false,
    seatIoId: formValues?.seatIoId,
    isSoldOut: false
  });

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    try {
      const categories = await getAllCategoriesApi()
      // const places = await getAllPlacesApi()
      // const stands = await getAllStandsApi()
      // const sieges = await getAllSiegesApi()
      // const socials = await getAllSocialsApi()

      setCategoriesData(categories)
      // setPlacesData(places.filter(el => !el.fromEvent))
      // setStandsData(stands.filter(el => !el.fromEvent))
      // setSiegesData(sieges)
      // setSocialsData(socials.filter(el => !el.fromEvent))
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleClose = (params) => {
    setOpen(false);
  };
  const handleCloseEventPlaces = (params) => {
    setOpenEventPlaces(false);
  };
  const handleCloseEventSieges = (params) => {
    setOpenEventSieges(false);
  };
  const handleCloseEventStands = (params) => {
    setOpenEventStand(false);
  };
  const handleCloseEventSocial = (params) => {
    setOpenEventSocial(false)
  }

  const handleCloseSelectedEventPlaces = (params) => {
    setOpenEventSelectedPlaces(false);
  };
  const handleCloseSelectedEventSieges = (params) => {
    setOpenEventSelectedSieges(false);
  };
  const handleCloseSelectedEventStands = (params) => {
    setOpenEventSelectedStand(false);
  };
  const handleCloseSelectedEventSocial = (params) => {
    setOpenEventSelectedSocial(false)
  }

  const handleAddNewCategoryModal = () => {
    setOpen(true);
  };
  const handleAddNewPlaceModal = () => {
    setOpenEventPlaces(true);
  };
  const handleAddNewSiegeModal = () => {
    setOpenEventSieges(true);
  };
  const handleAddNewResellerModal = () => {
    setOpenEventStand(true);
  };
  const handleAddNewsocialModal = () => {
    setOpenEventSocial(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormDataValues = {
      ...formDataValues,
      [name]: value,
    };
    setFormDataValues(newFormDataValues);

    // Immediately pass the updated form data
    formValues(newFormDataValues);
  };

  const handleIsOnlineSwitch = (onlineReservationVal) => {
    const newFormDataValues = {
      ...formDataValues,
      onlineReservation: onlineReservationVal,
    };

    setFormDataValues(newFormDataValues);
    formValues(newFormDataValues);
  }

  const handleisIsSelectetivePlacesSwitch = (IsSelectetivePlacesValue) => {
    const newFormDataValues = {
      ...formDataValues,
      isSelectetivePlaces: IsSelectetivePlacesValue,
    };

    setFormDataValues(newFormDataValues);
    formValues(newFormDataValues);
  }

  const handleisIsSoldOutSwitch = (isSoldOut) => {
    const newFormDataValues = {
      ...formDataValues,
      isSoldOut: isSoldOut,
    };

    setFormDataValues(newFormDataValues);
    formValues(newFormDataValues);
  }

  const handleAutocompletePlaceChange = (event, newValue) => {
    const newFormDataValues = {
      ...formDataValues,
      eventPlace: newValue,
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }
  const handleAutocompleteCategoryChange = (event, newValue) => {
    const newFormDataValues = {
      ...formDataValues,
      eventCategory: newValue,
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }
  const handleAutocompleteStandChange = (event, newValue) => {
    const newFormDataValues = {
      ...formDataValues,
      eventStand: newValue,
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }
  const handleAutocompleteSiegeChange = (event, newValue) => {
    const newFormDataValues = {
      ...formDataValues,
      eventSiege: newValue,
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }
  const handleAutocompleteSocialChange = (event, newValue) => {
    const newFormDataValues = {
      ...formDataValues,
      socialLink: newValue,
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }

  const handleRefreshData = () => {
    getInitialData()
  }

  const handleNewPlaceData = (data) => {
    setPlacesData((prevData) => [...prevData, data]);
  }
  const handleSelectedNewPlaceData = (data) => {
    const selectedEventPlaces = data.eventPlaces

    setPlacesData(() => selectedEventPlaces)

    // Update formDataValues with the selected places
    setFormDataValues((prevFormData) => ({
      ...prevFormData,
      eventPlace: selectedEventPlaces
    }));

    // Pass updated formDataValues to parent or other components as required
    formValues({
      ...formDataValues,
      eventPlace: selectedEventPlaces
    });
  }

  const handleNewStandData = (data) => {
    setStandsData((prevData) => [...prevData, data])
  }
  const handleSelectedNewStandData = (data) => {
    const selectedEventStands = data.eventStand

    setStandsData(() => selectedEventStands)

    // Update formDataValues with the selected places
    setFormDataValues((prevFormData) => ({
      ...prevFormData,
      eventStand: selectedEventStands
    }));

    // Pass updated formDataValues to parent or other components as required
    formValues({
      ...formDataValues,
      eventStand: selectedEventStands
    });
  }

  const handleNewSiegeData = (data) => {
    setSiegesData((prevData) => [...prevData, data])
  }
  const handleSelectedNewSiegeData = (data) => {
    const selectedEventSiege = data.eventSiege

    setSiegesData(() => selectedEventSiege)

    // Update formDataValues with the selected places
    setFormDataValues((prevFormData) => ({
      ...prevFormData,
      eventSiege: selectedEventSiege
    }));

    // Pass updated formDataValues to parent or other components as required
    formValues({
      ...formDataValues,
      eventSiege: selectedEventSiege
    });

    console.log("selectedEventSiege =>", selectedEventSiege)
  }

  const handleNewSocialData = (data) => {
    setSocialsData((prevData) => [...prevData, data])
  }

  const handleEditePlacesListModal = () => {
    setOpenEventSelectedPlaces(true)
  }
  const handleEditeStandsListModal = () => {
    setOpenEventSelectedStand(true)
  }
  const handleEditeSiegeListModal = () => {
    setOpenEventSelectedSieges(true)
  }
  const handleEditeSocialListModal = () => {
    setOpenEventSelectedSocial(true)
  }

  return (
    <Card>
      <EventCategories openEventCategory={open} closeEventCategory={handleClose} refreshData={handleRefreshData} isEdite={false} openModalItemData={null} />
      <EventPlaces openEventPlace={openEventPlaces} closeEventPlace={handleCloseEventPlaces} refreshData={handleRefreshData} isEdite={false} openModalItemData={null} newPlaceData={handleNewPlaceData} />
      <EventStands openEventStand={openEventStand} closeEventStand={handleCloseEventStands} refreshData={handleRefreshData} isEdite={false} openModalItemData={null} newStandData={handleNewStandData} />
      <EventSieges openEventSiege={openEventSieges} closeEventSiege={handleCloseEventSieges} refreshData={handleRefreshData} isEdite={false} openModalItemData={null} newSiegeData={handleNewSiegeData} />
      <EventSocial openEventSocial={openEventSocial} closeEventSocial={handleCloseEventSocial} refreshData={handleRefreshData} isEdite={false} openModalItemData={null} newSocialData={handleNewSocialData} />

      <EventSelectedPlaces openEventSelectedPlace={openEventSelectedPlaces} closeEventSelectedPlace={handleCloseSelectedEventPlaces} refreshData={handleRefreshData} isEdite={false} openModalItemData={formDataValues.eventPlace} newPlaceData={handleSelectedNewPlaceData} />
      <EventSelectedStands openEventSelectedStand={openEventSelectedStand} closeEventSelectedStand={handleCloseSelectedEventStands} refreshData={handleRefreshData} isEdite={false} openModalItemData={formDataValues.eventStand} newStandData={handleSelectedNewStandData} />
      <EventSelectedSieges openEventSelectedSiege={openEventSelectedSieges} closeEventSelectedSiege={handleCloseSelectedEventSieges} refreshData={handleRefreshData} isEdite={false} openModalItemData={formDataValues.eventSiege} newSiegeData={handleSelectedNewSiegeData} />
      <EventSelectedSocial openEventSelectedSocial={openEventSelectedSocial} closeEventSelectedSocial={handleCloseSelectedEventSocial} refreshData={handleRefreshData} isEdite={false} openModalItemData={formDataValues.socialLink} newSocialLinkData={handleNewPlaceData} />

      <MDBox p={3}>
        <MDTypography variant="h5">Informations sur l'événement</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Titre de l'événement"
                name="eventTitle"
                value={formDataValues.eventTitle}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Vidéo promotionnelle"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Adresse"
                name="address"
                value={formDataValues.address}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container xs={12}>
            <Grid item xs={12} md={6}>
              <MDBox>
                <MDBox display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Catégories
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  multiple
                  defaultValue={[]}
                  options={categoriesData}
                  getOptionLabel={(option) => option.name}
                  onChange={handleAutocompleteCategoryChange}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: { xs: 0, md: 5 } }}>
              <MDButton color="success" variant="text" onClick={handleAddNewCategoryModal}>
                Ajouter une nouvelle catégorie
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container xs={12}>
            <Grid item xs={12} md={6}>
              <MDBox>
                <MDBox display="inline-block">
                  <Icon fontSize="medium" color="success" style={{ cursor: 'pointer', position: 'relative', top: '5px', marginRight: '10px' }} onClick={handleEditePlacesListModal}>edit</Icon>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Lieu de l'événement :
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  multiple
                  defaultValue={[]}
                  options={placesData}
                  getOptionLabel={(option) => option.name}
                  onChange={handleAutocompletePlaceChange}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: { xs: 0, md: 5 } }}>
              <MDButton color="success" variant="text" onClick={handleAddNewPlaceModal}>
                Ajouter un nouveau lieu
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container xs={12}>
            <Grid item xs={12} md={6}>
              <MDBox>
                <MDBox display="inline-block">
                  <Icon fontSize="medium" color="success" style={{ cursor: 'pointer', position: 'relative', top: '5px', marginRight: '10px' }} onClick={handleEditeStandsListModal}>edit</Icon>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Stands
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  multiple
                  defaultValue={[]}
                  options={standsData}
                  getOptionLabel={(option) => option.name}
                  onChange={handleAutocompleteStandChange}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: { xs: 0, md: 5 } }}>
              <MDButton color="success" variant="text" onClick={handleAddNewResellerModal}>
                Ajouter un nouveau stand
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container xs={12}>
            <Grid item xs={12} md={6}>
              <MDBox>
                <MDBox display="inline-block">
                  <Icon fontSize="medium" color="success" style={{ cursor: 'pointer', position: 'relative', top: '5px', marginRight: '10px' }} onClick={handleEditeSiegeListModal}>edit</Icon>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Siège (VIP / Normal ...)
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  multiple
                  defaultValue={[]}
                  options={siegesData}
                  getOptionLabel={(option) => option.name}
                  onChange={handleAutocompleteSiegeChange}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: { xs: 0, md: 5 } }}>
              <MDButton color="success" variant="text" onClick={handleAddNewSiegeModal}>
                Ajouter un nouveau siège
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container xs={12}>
            <Grid item xs={12} md={6}>
              <MDBox>
                <MDBox display="inline-block">
                  {/* <Icon fontSize="medium" color="success" style={{ cursor: 'pointer', position: 'relative', top: '5px', marginRight: '10px' }} onClick={handleEditeSocialListModal}>edit</Icon> */}
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Réseaux sociaux
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  multiple
                  defaultValue={[]}
                  options={socialsData}
                  getOptionLabel={(option) => option.name}
                  onChange={handleAutocompleteSocialChange}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: { xs: 0, md: 5 } }}>
              <MDButton color="success" variant="text" onClick={handleAddNewsocialModal}>
                Ajouter
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <FormControlLabel
            control={
              <Switch
                checked={formDataValues.onlineReservation}
                onChange={() => handleIsOnlineSwitch(!formDataValues.onlineReservation)}
              />
            }
            label="Résérvation online"
          />
        </MDBox>

        {/* <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formDataValues.isSoldOut}
                    onChange={() => handleisIsSoldOutSwitch(!formDataValues.isSoldOut)}
                  />
                }
                label="Soldout"
              />
            </Grid>
          </Grid>
        </MDBox> */}

        <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formDataValues.isSelectetivePlaces}
                    onChange={() => handleisIsSelectetivePlacesSwitch(!formDataValues.isSelectetivePlaces)}
                  />
                }
                label="Réservation via le plan"
              />
            </Grid>
            {
              formDataValues.isSelectetivePlaces &&
                <Grid item xs={12} md={6}>
                  <FormField
                    type="text"
                    label="Seat.io id"
                    name="seatIoId"
                    value={formDataValues.seatIoId}
                    onChange={handleInputChange}
                  />
                </Grid>
            }
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12} md={6}>
              <MDBox>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  Début des ventes
                </MDTypography>
                <FormField
                  type="date"
                  label=""
                  name="startDate"
                  value={formDataValues.startDate}
                  onChange={handleInputChange}
                />
                <FormField
                  type="time"
                  label=""
                  name="startTime"
                  value={formDataValues.startTime}
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  Fin des ventes
                </MDTypography>
                <FormField
                  type="date"
                  label=""
                  name="endDate"
                  value={formDataValues.endDate}
                  onChange={handleInputChange}
                />
                <FormField
                  type="time"
                  label=""
                  name="endTime"
                  value={formDataValues.endTime}
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  Date et heure de l'événement
                </MDTypography>
                <FormField
                  type="date"
                  label=""
                  name="eventDate"
                  value={formDataValues.eventDate}
                  onChange={handleInputChange}
                />
                <FormField
                  type="time"
                  label=""
                  name="eventTime"
                  value={formDataValues.eventTime}
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  Date & heure de la fermeture des portes
                </MDTypography>
                <FormField
                  type="date"
                  label=""
                  name="eventDoorClosingDate"
                  value={formDataValues.eventDoorClosingDate}
                  onChange={handleInputChange}
                />
                <FormField
                  type="time"
                  label=""
                  name="eventDoorClosingTime"
                  value={formDataValues.eventDoorClosingTime}
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

FormFields.propTypes = {
  formValues: PropTypes.string.isRequired,
};

export default FormFields;
